import * as React from "react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { PromiseFunction, executePromisesSequentially } from "../../../../functions/executePromisesSequentially";
import { usePostJobFile } from "../../../../hooks/api/job/usePostJobFile";
import { PrimaryButton } from "../../../atoms/Button/PrimaryButton";
import { FileUploader, IFileInput } from "../../../atoms/FileUploader/FileUploader";
import { Spinner } from "../../../atoms/Spinner/Spinner";

interface IProps {
    readonly jobId: string;
}

export const UploadJobFiles: React.FC<IProps> = ({ jobId }) => {
    const [t] = useTranslation();
    const [images, setImages] = useState<Array<IFileInput>>([]);

    const { mutation: postJobFile } = usePostJobFile(() => {});
    const onFilesLoaded = useCallback(
        (files: Array<IFileInput>) => {
            setImages(files);
        },
        [setImages]
    );

    const onContinueClicked = useCallback(async () => {
        const fileUploadPromises: Array<PromiseFunction> = [];
        for (const file of images) {
            const p = () =>
                new Promise<void>(async (resolve) => {
                    await postJobFile.mutateAsync({ jobId, file });
                    resolve();
                });
            fileUploadPromises.push(p);
        }
        await executePromisesSequentially(fileUploadPromises);
        setImages([]);
    }, [images, setImages]);
    if (postJobFile.isLoading) {
        return <Spinner />;
    }

    const uploadDisabled = images.length === 0 || postJobFile.isLoading;

    return (
        <>
            <FileUploader
                label={t("createJobPage.stage2.uploadFileLabel")}
                onFilesLoaded={onFilesLoaded}
                multiple={true}
                accept="image/*"
            />
            <PrimaryButton onClick={onContinueClicked} type="button" disabled={uploadDisabled}>
                {t("createJobPage.continue")}
            </PrimaryButton>
        </>
    );
};
